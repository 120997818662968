import {mapActions, mapGetters, mapMutations} from "vuex";
import { Carousel, Slide } from 'vue-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "trunk",
  components:{
    Carousel,
    Slide
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchTrunk(this.$route.params.slug)
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchTrunk(this.$route.params.slug)
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      trunk: 'pages/trunkShow'
    }),
  },

  methods:{
    ...mapActions({
      fetchTrunk: 'pages/GET_TRUNK_SHOW'
    }),
    ...mapMutations({

    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    },
  }
}